<template>
  <v-container fluid class="px-5">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <h1 class="font-weight-medium">Profile</h1>
        <v-btn-toggle mandatory v-model="tab" class="ml-auto" tile color="deep-purple accent-3">
          <v-btn :value="0"> Account </v-btn>
          <v-btn :value="1"> Security </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <account-settings-account v-if="tab === 0"></account-settings-account>
    <account-settings-security v-else></account-settings-security>
  </v-container>
</template>

<script>
import AccountSettingsAccount from './AccountSettingsAccount.vue'
import AccountSettingsSecurity from './AccountSettingsSecurity.vue'

export default {
  components: {
    AccountSettingsAccount,
    AccountSettingsSecurity,
  },
  data() {
    return {
      tab: 0,
    }
  },
}
</script>

<template>
  <v-card flat>
    <v-card-text>
      <v-form ref="form" v-model="isFormValid" class="multi-col-validation mt-6">
        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <div>
                  <div class="d-flex">
                    <v-avatar rounded size="120" class="me-6">
                      <v-img :src="form.avatar || require('@/assets/images/avatars/3.jpg')"></v-img>
                    </v-avatar>
                    <div class="d-flex justify-center flex-column">
                      <h1 class="pl-1">{{ user.name }}</h1>
                      <h4 class="pl-1">{{ user.role.text }}</h4>
                      <p class="mt-2">
                        <span
                          ><v-icon class="mr-1 mb-1" size="20">{{ icons.mdiAccountCircleOutline }}</v-icon
                          >{{ user.department.name }}</span
                        >
                        <span class="ml-6"
                          ><v-icon class="mr-1 mb-1" size="20" small>{{ icons.mdiAccountTie }}</v-icon
                          >{{ user.designation.name }}</span
                        >
                      </p>
                    </div>
                  </div>
                  <v-btn color="primary" class="me-3 mt-5" @click="$refs.refInputEl.click()">
                    <v-icon class="d-sm-none">
                      {{ icons.mdiCloudUploadOutline }}
                    </v-icon>
                    <span class="d-none d-sm-block">Upload new photo</span>
                  </v-btn>

                  <input ref="refInputEl" type="file" accept="image/*" :hidden="true" @change="handleFileChange" />

                  <v-btn color="error" outlined class="mt-5" @click="resetAvatar"> Reset </v-btn>
                  <p class="text-sm mt-5">Allowed JPG, GIF or PNG. Max size of 800K</p>
                </div>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  :rules="[v => !!v || 'Name is required']"
                  label="Name"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="form.email" label="E-mail" outlined></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  prefix="+91"
                  v-model="form.mobileNumber"
                  :rules="[v => !!v || 'Mobile Number is required']"
                  label="Mobile Number"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-btn color="primary" class="me-3 mt-4" @click="updateAccountInfo"> Save changes </v-btn>
              </v-col></v-row
            >
          </v-col>
          <v-col cols="12" sm="4" md="6" class="d-none d-md-flex justify-center position-relative">
            <v-img
              contain
              max-width="170"
              src="@/assets/images/3d-characters/pose-m-1.png"
              class="security-character"
            ></v-img>
          </v-col>
          <v-col cols="6"> </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline, mdiEmail, mdiAccountCircleOutline, mdiAccountTie } from '@mdi/js'
import { mapState } from 'vuex'
import { convertFileToBase64 } from '@/setup/helpers'
import { updateAccountInfo } from '@/api/auth'

export default {
  data() {
    return {
      imageUrl: '',
      isFormValid: true,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiEmail,
        mdiAccountCircleOutline,
        mdiAccountTie,
      },
      form: {
        name: null,
        email: null,
        mobileNumber: null,
        avatar: null,
      },
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    email() {
      return this.user?.email
    },
  },
  created() {
    this.fillForm()
  },
  methods: {
    resetAvatar() {
      this.form.avatar = this.user.avatar
      this.$refs['refInputEl'].value = null
    },
    async handleFileChange(event) {
      const file = event.target.files[0]
      if (!file) return
      if (!file.type.startsWith('image')) {
        return this.$message('Invalid file', 'error')
      }
      if (file.size > 800000) return this.$message('File size must be smaller or equal to 800KB', 'error')
      this.form.avatar = `data:image/png;base64, ${await convertFileToBase64(file)}`
    },

    async updateAccountInfo() {
      try {
        this.$loader(true)
        await this.$refs.form.validate()
        if (!this.isFormValid) return
        await updateAccountInfo({ ...this.form, mobileNumber: `+91${this.form.mobileNumber}` })
        this.$message('Update account info successfully')
        await this.$store.dispatch('auth/getCurrentUser')
      } finally {
        this.$loader(false)
      }
    },

    fillForm() {
      for (const field in this.form) {
        if (field === 'mobileNumber') this.form.mobileNumber = this.user[field].split('+91').pop()
        else this.form[field] = this.user[field]
      }
    },
  },
}
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
</style>

<template>
  <v-card flat class="mt-5">
    <v-form ref="form" v-model="isFormValid">
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12" sm="8" md="6">
            <!-- current password -->
            <v-text-field
              v-model="form.currentPassword"
              :type="isCurrentPasswordVisible ? 'text' : 'password'"
              :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              label="Current Password"
              outlined
              @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
              :rules="[v => !!v || 'Current password is required']"
            ></v-text-field>

            <!-- new password -->
            <v-text-field
              class="mt-3"
              v-model="form.newPassword"
              :type="isPasswordVisible ? 'text' : 'password'"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              label="New Password"
              outlined
              persistent-hint
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="passwordRules"
            ></v-text-field>

            <!-- confirm password -->
            <v-text-field
              v-model="form.confirmPassword"
              :type="isConfirmPasswordVisible ? 'text' : 'password'"
              :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              label="Confirm New Password"
              outlined
              class="mt-3"
              @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
              :rules="passwordConfirmationRules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="4" md="6" class="d-none d-sm-flex justify-center position-relative">
            <v-img
              contain
              max-width="170"
              src="@/assets/images/3d-characters/pose-m-1.png"
              class="security-character"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" class="me-3 mt-3" @click="changePassword"> Update password </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { changePassword } from '@/api/auth'

export default {
  computed: {
    passwordConfirmationRules: function () {
      return [v => !!v || 'Password Confirmation is required', v => v === this.form.newPassword || 'Password not match']
    },
  },
  data() {
    return {
      isFormValid: true,
      isCurrentPasswordVisible: false,
      isConfirmPasswordVisible: false,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      isPasswordVisible: false,
      form: {
        newPassword: '',
        currentPassword: '',
        confirmPassword: '',
      },
      passwordRules: [
        v => !!v || 'Password is required',
        v =>
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(v) ||
          'Password must contain at least 8 characters including one upper case letter, one lower case letter and one number',
      ],
    }
  },
  methods: {
    resetForm() {
      this.form.newPassword = ''
      this.form.confirmPassword = ''
      this.form.currentPassword = ''
      this.$refs.form.resetValidation()
    },
    async changePassword() {
      try {
        this.$loader(true)
        await this.$refs.form.validate()
        if (!this.isFormValid) return
        await changePassword(this.form)
        this.resetForm()
        this.$message('Change password successfully')
      } finally {
        this.$loader(false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
